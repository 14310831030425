<template>
  <span>Profile page comes here...</span>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Profile",
};
</script>
